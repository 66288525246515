import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const app = initializeApp(window.PUBLIC_ENV.FIREBASE_CONFIG);

const auth = getAuth(app);

const functions = getFunctions(app, "asia-northeast1");

const firestore = getFirestore(app);

if (window.PUBLIC_ENV.FRONTEND_ENV === "dev") {
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(firestore, "localhost", 9090);
  connectFunctionsEmulator(functions, "localhost", 5001);
}

export { auth, firestore, functions };
